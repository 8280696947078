import { pathOr } from '../../assets/js/helpers';
import { transformedUserList, transformedUserInfo } from '../../assets/js/transform/user';
import {
	getUserAPI,
	getUsersAPI,
	postUserAPI,
	putUserAPI,
	postMarkAsAPI,
} from '../../services/api/users.api';
import {
	CREATE_USER_SUCCESS,
	CREATE_USER_FAILURE,
	CREATE_USER_REQUEST,
	CREATE_USER_CLEAR_ERROR,
	GET_USERS_REQUEST,
	GET_USERS_SUCCESS,
	GET_USERS_FAILURE,
	GET_USER_REQUEST,
	GET_USER_SUCCESS,
	GET_USER_FAILURE,
	UPDATE_USER_REQUEST,
	UPDATE_USER_SUCCESS,
	UPDATE_USER_FAILURE,
	UPDATE_USER_CLEAR_ERROR,
	UPDATE_MARK_AS_REQUEST,
	UPDATE_MARK_AS_SUCCESS,
	UPDATE_MARK_AS_FAILURE,
} from '../types';

const defaultErrors = {
	username: false,
	email: false,
};

export default {
	namespaced: true,
	state: {
		create: {
			isLoading: false,
			isSuccess: false,
			fieldErrors: defaultErrors,
			data: {},
		},
		// User list
		list: {
			isLoading: false,
			data: [],
			meta: {
				currentPage: 1,
				lastPage: 0,
			},
		},
		edit: {
			isLoading: false,
			isUpdating: false,
			isSuccess: false,
			fieldErrors: defaultErrors,
			data: {},
		},
	},
	getters: {
		userList(state) {
			return transformedUserList(state.list.data);
		},
		userInfo(state) {
			return transformedUserInfo(state.edit.data);
		},
	},
	mutations: {
		[CREATE_USER_REQUEST](state) {
			state.create = {
				...state.create,
				isLoading: true,
			};
		},
		[CREATE_USER_SUCCESS](state, response) {
			const { data } = response;

			state.create = {
				...state.create,
				data,
				isLoading: false,
				isSuccess: true,
			};
		},
		[CREATE_USER_FAILURE](state, response) {
			state.create = {
				...state.create,
				fieldErrors: {
					...response.error,
					username: pathOr(false, ['errors', 'username', '0'])(response),
					email: pathOr(false, ['errors', 'email', '0'])(response),
				},
				isLoading: false,
				isSuccess: false,
			};
		},
		[CREATE_USER_CLEAR_ERROR](state) {
			state.create = {
				...state.create,
				fieldErrors: defaultErrors,
				isSuccess: false,
			};
		},
		[GET_USERS_REQUEST](state, { page }) {
			state.list = {
				...state.list,
				isLoading: true,
				meta: {
					...state.list.meta,
					currentPage: page,
				},
			};
		},
		[GET_USERS_SUCCESS](state, { data, meta, ...rest }) {
			state.list = {
				rest,
				data,
				meta: {
					...meta,
					currentPage: meta.current_page,
					lastPage: meta.last_page,
				},
				isLoading: false,
			};
		},
		[GET_USERS_FAILURE](state) {
			state.list = {
				...state.list,
				isLoading: false,
			};
		},
		[GET_USER_REQUEST](state) {
			state.edit = {
				...state.edit,
				isLoading: true,
				fieldErrors: defaultErrors,
			};
		},
		[GET_USER_SUCCESS](state, { data }) {
			state.edit = {
				...state.edit,
				data,
				isLoading: false,
			};
		},
		[GET_USER_FAILURE](state) {
			state.edit = {
				...state.edit,
				data: {},
				isLoading: false,
				fieldErrors: defaultErrors,
			};
		},
		[UPDATE_USER_REQUEST](state) {
			state.edit = {
				...state.edit,
				isUpdating: true,
				isSuccess: false,
				fieldErrors: defaultErrors,
			};
		},
		[UPDATE_USER_SUCCESS](state, { data }) {
			state.edit = {
				...state.edit,
				data,
				isUpdating: false,
				isSuccess: true,
			};
		},
		[UPDATE_USER_FAILURE](state, response = {}) {
			state.edit = {
				...state.edit,
				data: {},
				isUpdating: false,
				isSuccess: false,
				fieldErrors: {
					...response.error,
					username: pathOr(false, ['errors', 'username', '0'])(response),
					email: pathOr(false, ['errors', 'email', '0'])(response),
				},
			};
		},
		[UPDATE_USER_CLEAR_ERROR](state) {
			state.edit = {
				...state.edit,
				fieldErrors: defaultErrors,
				isSuccess: false,
			};
		},
		[UPDATE_MARK_AS_REQUEST](state) {
			state.edit = {
				...state.edit,
				isUpdating: true,
			};
		},
		[UPDATE_MARK_AS_SUCCESS](state) {
			state.edit = {
				...state.edit,
				isUpdating: false,
			};
		},
		[UPDATE_MARK_AS_FAILURE](state) {
			state.edit = {
				...state.edit,
				isUpdating: false,
			};
		},
	},
	actions: {
		async getUser({ commit }, userID) {
			try {
				commit(GET_USER_REQUEST);
				const { data } = await getUserAPI(userID);
				commit(GET_USER_SUCCESS, data);
			} catch (error) {
				const { data } = error.response;
				commit(GET_USER_FAILURE, data);
			}
		},

		async getUsers({ commit }, query) {
			try {
				commit(GET_USERS_REQUEST, query);

				const { data } = await getUsersAPI(query);

				commit(GET_USERS_SUCCESS, data);
			} catch (error) {
				commit(GET_USERS_FAILURE);
			}
		},

		async postUser({ commit }, userDataStore) {
			try {
				commit(CREATE_USER_REQUEST);

				const { data } = await postUserAPI(userDataStore);
				commit(CREATE_USER_SUCCESS, data);
			} catch (error) {
				const { data } = error.response;
				commit(CREATE_USER_FAILURE, data);
			}
		},

		async updateUser({ commit }, { userId, userDataStore }) {
			try {
				commit(UPDATE_USER_REQUEST);
				const { data } = await putUserAPI(userId, userDataStore);
				commit(UPDATE_USER_SUCCESS, data);
			} catch (error) {
				const { data } = error.response;

				commit(UPDATE_USER_FAILURE, data);
			}
		},

		async updateMarkAs({ commit }, { userId, status }) {
			try {
				commit(UPDATE_MARK_AS_REQUEST);
				await postMarkAsAPI(userId, status);
				commit(UPDATE_MARK_AS_SUCCESS);
			} catch {
				commit(UPDATE_MARK_AS_FAILURE);
			}
		},

		clearError({ commit }) {
			commit(CREATE_USER_CLEAR_ERROR);
		},
	},
};
