import httpClient from './httpClient';
import { REGISTER_STATUS } from '../../enums/social';

export const USERS_END_POINT = '/backoffice/users';
export const MARK_AS_APPROVED = 'mark-as-approved';
export const MARK_AS_REJECTED = 'mark-as-rejected';

export const getUserAPI = (id) => httpClient.get(`${USERS_END_POINT}/${id}`);
export const getUsersAPI = (params) => httpClient.get(USERS_END_POINT, { params });
export const postUserAPI = (params) => httpClient.post(USERS_END_POINT, params);
export const putUserAPI = (id, params) => httpClient.put(`${USERS_END_POINT}/${id}`, params);

export const postMarkAsAPI = (id, status = REGISTER_STATUS.REJECTED) => {
	switch (status) {
		case REGISTER_STATUS.APPROVED:
			return httpClient.post(`${USERS_END_POINT}/${id}/${MARK_AS_APPROVED}`);
		case REGISTER_STATUS.REJECTED:
		default:
			return httpClient.post(`${USERS_END_POINT}/${id}/${MARK_AS_REJECTED}`);
	}
};
