export default Object.freeze({
	UNEXPECTED_ERROR: 'page.login.unexpectedError',
	REQUIRED_FIELD: 'page.login.requireField',
	REQUIRED_CHECKED: 'page.login.requireChecked',
	INVALID_FORMAT: 'page.login.invalidFormat',
	INVALID_MIN_VALUE: 'page.login.invalidMinValue',
	LOGIN_FAILURE: 'page.login.userNameOrPassWordIsInCorrect',
	LOGIN_TOO_MANY: 'page.login.tooManyAttempts',
	LOGIN_ACCOUNT_INACTIVE: 'page.login.accountInactive',
	REGISTER_SOCIAL_FAILURE: 'page.register.social.phoneOrEmailIsInCorrect',
	REGISTER_SOCIAL_MAXIMUM_LENGTH: 'page.register.social.maximumLength',
});
