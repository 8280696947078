import attributeSets from './page/attributeSets/th';
import attributeKeys from './page/attributeKeys/th';
import banner from './page/banner/en';
import categories from './page/categories/th';
import contentBlock from './page/contentBlock/th';
import login from './page/login/th';
import products from './page/products/th';
import searchSynonims from './page/searchSynonims/th';
import users from './page/users/th';
import productLabels from './page/productLabels/th';
import modalMediaLibrary from './components/modalMediaLibrary/th';
import manageFilter from './components/manageFilter/th';
import contentWrapper from './components/contentWrapper/th';
import accordionKeys from './page/accordionKeys/th';
import global from './global/th';
import register from './page/register/th';

export default {
	page: {
		attributeSets,
		attributeKeys,
		banner,
		categories,
		contentBlock,
		login,
		products,
		searchSynonims,
		users,
		productLabels,
		accordionKeys,
		register,
	},
	components: {
		modalMediaLibrary,
		manageFilter,
		contentWrapper,
	},
	global,
};
