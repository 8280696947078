import { pathOr, datetimeFormat } from '../helpers';
import { REGISTER_STATUS } from '../../../enums/social';

export const transformedUser = (user) => {
	const firstName = pathOr(null, ['profile', 'first_name'])(user);
	const lastName = pathOr(null, ['profile', 'last_name'])(user);
	const storeName = pathOr('-', ['profile', 'branch', 'name'])(user);
	const storeId = pathOr('-', ['profile', 'branch', 'id'])(user);
	const storeItecId = pathOr('-', ['profile', 'branch', 'itec_id'])(user);
	const staffId = pathOr('-', ['profile', 'staff_id'])(user);

	const name = (!firstName && !lastName)
		? '-'
		: `${firstName}${lastName ? ` ${lastName}` : ''}`;

	return {
		id: pathOr(0, ['id'])(user),
		createdAt: user && user.created_at ? datetimeFormat(user.created_at) : null,
		staffId,
		username: pathOr('-', ['username'])(user),
		name,
		storeName,
		storeId,
		provider: pathOr(null, ['provider_name'])(user),
		storeItecId,
		group: pathOr(null, ['role', 'name'])(user),
		approved: pathOr(null, ['registration_status'])(user),
		status: pathOr(0, ['is_active'])(user),
	};
};

export const transformedUserList = (users = []) => {
	const data = users.map((user) => transformedUser(user));

	return data;
};

export const transformedUserInfo = (user = {}) => {
	const status = pathOr(0, ['is_active'])(user);

	return {
		staffId: pathOr(null, ['profile', 'staff_id'])(user),
		username: pathOr(null, ['username'])(user),
		firstName: pathOr(null, ['profile', 'first_name'])(user),
		lastName: pathOr(null, ['profile', 'last_name'])(user),
		note: pathOr(null, ['profile', 'note'])(user),
		storeName: pathOr(null, ['profile', 'branch', 'name'])(user),
		storeId: pathOr(null, ['profile', 'branch', 'id'])(user),
		storeItecId: pathOr(null, ['profile', 'branch', 'itec_id'])(user),
		phoneNumber: pathOr(null, ['profile', 'phone_number'])(user),
		email: pathOr(null, ['email'])(user),
		status: status === 1,
		group: pathOr(null, ['role', 'name'])(user),
		provider: pathOr(null, ['provider_name'])(user),
		approved: pathOr(REGISTER_STATUS.PENDING, ['registration_status'])(user),
		id: pathOr(null, ['profile', 'user_id'])(user),
	};
};
